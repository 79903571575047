exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-preview-tsx": () => import("./../../../src/pages/blog-preview.tsx" /* webpackChunkName: "component---src-pages-blog-preview-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-builder-preview-tsx": () => import("./../../../src/pages/builder-preview.tsx" /* webpackChunkName: "component---src-pages-builder-preview-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-consent-tsx": () => import("./../../../src/pages/consent.tsx" /* webpackChunkName: "component---src-pages-consent-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-page-builder-tsx": () => import("./../../../src/pages/page-builder.tsx" /* webpackChunkName: "component---src-pages-page-builder-tsx" */),
  "component---src-pages-r-tsx": () => import("./../../../src/pages/r.tsx" /* webpackChunkName: "component---src-pages-r-tsx" */),
  "component---src-pages-refer-friends-tsx": () => import("./../../../src/pages/refer-friends.tsx" /* webpackChunkName: "component---src-pages-refer-friends-tsx" */),
  "component---src-pages-reviews-tsx": () => import("./../../../src/pages/reviews.tsx" /* webpackChunkName: "component---src-pages-reviews-tsx" */),
  "component---src-pages-showrooms-search-tsx": () => import("./../../../src/pages/showrooms/search.tsx" /* webpackChunkName: "component---src-pages-showrooms-search-tsx" */),
  "component---src-pages-ssr-products-tsx": () => import("./../../../src/pages/ssr/products.tsx" /* webpackChunkName: "component---src-pages-ssr-products-tsx" */),
  "component---src-pages-stack-preview-tsx": () => import("./../../../src/pages/stack-preview.tsx" /* webpackChunkName: "component---src-pages-stack-preview-tsx" */),
  "component---src-pages-swatches-tsx": () => import("./../../../src/pages/swatches.tsx" /* webpackChunkName: "component---src-pages-swatches-tsx" */),
  "component---src-templates-blog-post-blog-post-tsx": () => import("./../../../src/templates/BlogPost/BlogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-blog-post-tsx" */),
  "component---src-templates-builder-io-builder-io-page-tsx": () => import("./../../../src/templates/BuilderIO/BuilderIOPage.tsx" /* webpackChunkName: "component---src-templates-builder-io-builder-io-page-tsx" */),
  "component---src-templates-catalog-catalog-page-tsx": () => import("./../../../src/templates/Catalog/CatalogPage.tsx" /* webpackChunkName: "component---src-templates-catalog-catalog-page-tsx" */),
  "component---src-templates-configurator-page-configurator-page-tsx": () => import("./../../../src/templates/ConfiguratorPage/ConfiguratorPage.tsx" /* webpackChunkName: "component---src-templates-configurator-page-configurator-page-tsx" */),
  "component---src-templates-form-page-form-page-tsx": () => import("./../../../src/templates/FormPage/FormPage.tsx" /* webpackChunkName: "component---src-templates-form-page-form-page-tsx" */),
  "component---src-templates-page-page-tsx": () => import("./../../../src/templates/Page/Page.tsx" /* webpackChunkName: "component---src-templates-page-page-tsx" */),
  "component---src-templates-product-details-stack-product-details-stack-tsx": () => import("./../../../src/templates/ProductDetailsStack/ProductDetailsStack.tsx" /* webpackChunkName: "component---src-templates-product-details-stack-product-details-stack-tsx" */),
  "component---src-templates-showroom-page-showroom-page-tsx": () => import("./../../../src/templates/ShowroomPage/ShowroomPage.tsx" /* webpackChunkName: "component---src-templates-showroom-page-showroom-page-tsx" */),
  "component---src-templates-text-page-text-page-tsx": () => import("./../../../src/templates/TextPage/TextPage.tsx" /* webpackChunkName: "component---src-templates-text-page-text-page-tsx" */)
}

