import React, { useState } from "react";
import fetch from "isomorphic-fetch";
import shuffle from "lodash/shuffle";

import * as Analytics from "../../utils/analytics";

import { showroomsSearchConfig } from "../../../configs/showrooms";

const defaultState = {
  userLocation: {
    name: "Venice Beach, Los Angeles, CA, USA",
    latitude: 33.9850469,
    longitude: -118.46948320000001,
  },
  showrooms: {
    location: null,
    items: [],
    backups: [],
    loading: false,
    distance: 50,
    retry: true,
  },
};

const ShowroomsContext = React.createContext({
  state: defaultState,
  setUserLocation: () => { },
  updateDistanceFilter: () => { },
  loadShowrooms: () => Promise.resolve([]),
  loadRandomShowrooms: () => { },
  setUserDefaultLocation: country => { },
});

const getLocation = locations => {
  const place = locations[0];

  return {
    latitude: place.geometry.location.lat(),
    longitude: place.geometry.location.lng(),
  };
};

const showroomsRequest = (radius, latitude, longitude) => {
  let params = "";
  if (radius && latitude && longitude) {
    params = `nearby?radius=${radius}&latitude=${latitude}&longitude=${longitude}`;
  }

  return fetch(`${process.env.GATSBY_OJAI_PUBLIC_API}/showrooms/${params}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accepts: "application/json",
    },
  }).then(response => response.json());
};

class ShowroomsProvider extends React.Component {
  state = {
    ...defaultState,
  };

  setUserLocation = (name, location) => {
    const { showrooms } = this.state;

    this.setState({
      userLocation: {
        name,
        ...getLocation(location),
      },
      showrooms: {
        ...showrooms,
        distance: 50,
      },
    });
  };

  setUserDefaultLocation = country => {
    const { showrooms } = this.state;
    const userDefaultLocation = showroomsSearchConfig.defaultLocations[country];
    this.setState({
      userLocation: {
        ...userDefaultLocation,
      },
      showrooms: {
        ...showrooms,
      },
    });
  };

  loadShowrooms = () => {
    const { userLocation, showrooms } = this.state;

    const tracking = {
      input: userLocation.name,
      radius: showrooms.distance,
    };

    const setShowroom = (items, distance) =>
      this.setState({
        showrooms: {
          ...showrooms,
          location: {
            latitude: userLocation.latitude,
            longitude: userLocation.longitude,
          },
          distance: distance || showrooms.distance,
          items,
        },
      });

    return new Promise((resolve, reject) => {
      showroomsRequest(
        showrooms.distance,
        userLocation.latitude,
        userLocation.longitude
      ).then(json => {
        if (json.data?.length > 0) {
          setShowroom(json.data);

          return resolve(json.data);
        } else if (
          showrooms.distance < showroomsSearchConfig.searchFallbackRadius
        ) {
          Analytics.track("[GEO Results] No Nearby Showrooms Found", tracking);
          showroomsRequest(
            showroomsSearchConfig.searchFallbackRadius,
            userLocation.latitude,
            userLocation.longitude
          ).then(json => {
            if (json.data?.length > 0) {
              setShowroom(
                json.data,
                showroomsSearchConfig.searchFallbackRadius
              );
              return resolve(json.data);
            }
            return resolve(this.loadRandomShowrooms());
          });
        } else {
          return reject(this.loadRandomShowrooms());
        }
      });
    });
  };

  loadRandomShowrooms = () => {
    const { userLocation, showrooms } = this.state;
    return showroomsRequest().then(json => {
      const randomShowrooms = shuffle(json.data).slice(0, 6);
      this.setState({
        showrooms: {
          ...showrooms,
          location: {
            latitude: userLocation.latitude,
            longitude: userLocation.longitude,
          },
          items: [],
          backups: randomShowrooms,
        },
      });
    });
  };

  updateDistanceFilter = distance => {
    const { userLocation, showrooms } = this.state;

    const tracking = {
      from: showrooms.distance,
      to: distance,
    };
    Analytics.track("[GEO Results ] - Search Radius Changed", tracking);
    return showroomsRequest(
      distance,
      userLocation.latitude,
      userLocation.longitude
    ).then(json => {
      this.setState({
        showrooms: {
          ...this.state.showrooms,
          distance,
          items: json.data,
        },
      });
    });
  };

  render() {
    return (
      <ShowroomsContext.Provider
        value={{
          state: this.state,
          setUserLocation: this.setUserLocation,
          loadShowrooms: this.loadShowrooms,
          updateDistanceFilter: this.updateDistanceFilter,
          setUserDefaultLocation: this.setUserDefaultLocation,
        }}
      >
        {this.props.children}
      </ShowroomsContext.Provider>
    );
  }
}

export default ShowroomsContext;

export { ShowroomsProvider };
